import React, { useState } from "react";
import "./App.css";

const App: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [messageUnlocked, setMessageUnlocked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [responseSent, setResponseSent] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState("");
  const [customMessage, setCustomMessage] = useState("");

  const questions = [
    {
      question: "Come si dice 'hola' in italiano?",
      options: ["Ciao", "Buongiorno", "Arrivederci", "Grazie"],
      answer: "Ciao",
    },
    {
      question: "Qual è il tuo colore preferito?",
      options: ["Rosso", "Blu", "Verde", "Giallo"],
      answer: "Blu",
    },
    {
      question: "Come si dice 'gracias' in italiano?",
      options: ["Per favore", "Ciao", "Grazie", "Prego"],
      answer: "Grazie",
    },
  ];

  const handleAnswerClick = (selectedOption: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    let updatedScore = score;

    // Verificar si la respuesta es correcta y actualizar el score localmente
    if (selectedOption === currentQuestion.answer) {
      updatedScore++;
      setScore(updatedScore); // Actualizar el estado de score
    }

    const nextQuestionIndex = currentQuestionIndex + 1;

    // Si hay más preguntas, pasa a la siguiente
    if (nextQuestionIndex < questions.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      // Si es la última pregunta, marca el quiz como terminado
      setQuizFinished(true);

      // Verificar si el score local es igual al número total de preguntas para desbloquear el mensaje
      if (updatedScore === questions.length) {
        setMessageUnlocked(true);
      }
    }
  };

  const handleResponseSubmit = () => {
    setResponseSent(true);
  };

  return (
    <div className="app-container">
      {!quizFinished ? (
        <div className="card">
          <h2>{questions[currentQuestionIndex].question}</h2>
          <div className="options-container">
            {questions[currentQuestionIndex].options.map((option) => (
              <button
                key={option}
                className="option-button"
                onClick={() => handleAnswerClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <>
          {!messageUnlocked && !showMessage && (
            <div className="card">
              <h2>Non hai risposto correttamente a tutte le domande!</h2>
              <p>Non è possibile continuare.</p>
            </div>
          )}
          {messageUnlocked && !showMessage && (
            <div className="card">
              <h2>Hai sbloccato un messaggio importante!</h2>
              <p>
                Prima di continuare, assicurati di avere il tempo e la privacy
                para leggere il messaggio.
              </p>
              <button
                className="open-message-button"
                onClick={() => setShowMessage(true)}
              >
                Leggi il messaggio
              </button>
            </div>
          )}
          {showMessage && !responseSent && (
            <div className="message-content card">
              <h2>Ecco il tuo messaggio:</h2>
              <div className="message-box">
                {/* <p>
                  <strong>Hola Tere,</strong>
                </p>
                <p>
                  Primero que todo, quiero disculparme sinceramente por lo que
                  pasó el sábado. Sé que la situación te pudo haber puesto
                  incómoda y lamento haber complicado las cosas. Mi intención
                  nunca fue hacerte sentir mal. Después de reflexionar estos
                  días, me doy cuenta de que terminé confesándote mis
                  sentimientos porque llevaba mucho tiempo con esas emociones
                  acumuladas, pero el momento no fue el adecuado y lo lamento.
                </p>
                <p>
                  Quiero que sepas que respeto completamente lo que me dijiste:
                  que estás feliz con Paddy y que tu relación es importante para
                  ti. Lo entiendo y lo valoro, y por supuesto, lo voy a
                  respetar.
                </p>
                <p>
                  Eres una persona increíble, y quiero que sepas que te deseo lo
                  mejor siempre. Si en algún momento sientes que podemos hablar
                  de manera más tranquila y sincera, sin la presión de esa
                  noche, me gustaría tener esa conversación contigo.
                </p>
                <p>Cuídate mucho 😊.</p> */}
              </div>
              <h3>¿Cómo te gustaría responder?</h3>
              <div className="response-options">
                <button
                  className={`response-button ${
                    selectedResponse === "Hablemos pronto" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedResponse("Hablemos pronto")}
                >
                  Hablemos pronto
                </button>
                <button
                  className={`response-button ${
                    selectedResponse === "Te perdono" ? "selected" : ""
                  }`}
                  onClick={() => setSelectedResponse("Te perdono")}
                >
                  Te perdono
                </button>
                <button
                  className={`response-button ${
                    selectedResponse === "Quiero decirte algo diferente"
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    setSelectedResponse("Quiero decirte algo diferente")
                  }
                >
                  Quiero decirte algo diferente
                </button>
              </div>

              {selectedResponse === "Quiero decirte algo diferente" && (
                <textarea
                  className="custom-message-input"
                  value={customMessage}
                  onChange={(e) => setCustomMessage(e.target.value)}
                  placeholder="Escribe tu mensaje aquí..."
                />
              )}

              <button
                className="submit-response-button"
                onClick={handleResponseSubmit}
                disabled={!selectedResponse && customMessage === ""}
              >
                Enviar respuesta
              </button>
            </div>
          )}
          {responseSent && (
            <div className="response-sent-message card">
              <h2>Gracias por tu respuesta!</h2>
              <p>Tu respuesta ha sido enviada. Cuídate mucho.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default App;
